import React from 'react';
import { Routes, Route } from "react-router-dom";

import { Home } from './pages/Home';
import { Pricing } from "./pages/Pricing";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import Cookies from "./pages/Cookies";
import { Thanks } from './pages/Thanks';
import Terms from './pages/Terms';

const Router = () => {
  return (
    <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/cennik" element={<Pricing />}></Route>
          <Route path="/o-nas" element={<About />}></Route>
          <Route path="/kontakt" element={<Contact />}></Route>
          <Route path="/privacy-policy" element={<Cookies />}></Route>
          <Route path="/terms-and-conditions" element={<Terms />}></Route>
          <Route path="/dakujeme" element={<Thanks />}></Route>
          <Route path="/*" element={<Home />}></Route>
    </Routes>

  )
}
export default Router
