import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
import React, { Component } from 'react'
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import sal from 'sal.js';

import BrandsCarousel from '../components/BrandsCarousel';

class About extends Component {
    constructor(props) {
        super(props);
        this.bodyOverflow = this.bodyOverflow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {
          VizSensorActive: true,
          isModalActive: false,
          formSucc: true,
          submitButtonText: "Nachricht abschicken",
          name: '',
          email: '',
          company: '',
          msg: '',
        };
    
      }

    componentDidMount() {
        sal({
          threshold: 0.1,
          once: true
        });
        document.addEventListener("keydown", this.escFunction, false);
        
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'pageview',
        //     page: {
        //         url: "/kontakt",
        //         title: "kontakt"
        //     }
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    };
  
    componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.key === "Escape") {
          this.setState({isModalActive: false});
          this.bodyOverflow(false);
        }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let company = this.state.company;
        let msg = this.state.msg;
        const inputs = { name, email, company, msg };
        this.setState({submitButtonText: 'Sie wird gesendet...'});
        fetch('https://be.siedwebs.sk/api/send-contact', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Nachricht abschicken', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.bodyOverflow(true);
        })
        fetch('https://be.siedwebs.sk/api/send-contact-reply', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Nachricht abschicken', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.setState({isModalActive: true});
            this.bodyOverflow(true);
        })
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'contactForm',
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
      }
    
    bodyOverflow(bool) {
        if(bool) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector('body').removeAttribute("style");
        }
    }

    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>SIEDwebs | O nás</title>
                    <meta name="description" content="SIED Digital Group pomáha firmám dosiahnuť a prekonať svoj plný potenciál v digitálnej ére." />
                    <link rel="canonical" href='/o-nas' />

                    <meta property="og:url" content="https://siedwebs.sk/o-nas" />
                    <meta property="og:title" content="SIEDwebs | O nás" />
                    <meta property="og:description" content="SIED Digital Group pomáha firmám dosiahnuť a prekonať svoj plný potenciál v digitálnej ére." />

                    <meta property="twitter:url" content="https://siedwebs.sk/o-nas" />
                    <meta name="twitter:title" content="SIEDwebs | O nás" />
                    <meta name="twitter:description" content="SIED Digital Group pomáha firmám dosiahnuť a prekonať svoj plný potenciál v digitálnej ére." />
                </Helmet>
                {/*=====================================*/}
                {/*=       Breadcrumb Area Start       =*/}
                {/*=====================================*/}
                <div className="breadcrum-area">
                    <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                        <li>
                            <Link to="/">Domov</Link>
                        </li>
                        <li className="active">O nás</li>
                        </ul>
                        <h2 className="title h2">O nás</h2>
                    </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-17.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                    </ul>
                </div>
                {/*=====================================*/}
                {/*=       Contact  Area Start        =*/}
                {/*=====================================*/}
                <section className="section section-padding member-section">
                    <div className="container">
                    <div className="section-heading">
                        <span className="subtitle">Spoznajte zakladateľov</span>
                        <h2 className="title">Od vízie k realite</h2>
                    </div>
                    <div className="row">
                        <div className="col-6 member">
                            <div>
                                <div className='member-wrapper'>
                                    <img loading="lazy" height="" width=""src="assets/media/sied/edwin.png" alt="edwin" />
                                </div>
                                <div>
                                    <h2 className="title">Edwin C.</h2>
                                    <p className="subtitle">Founder & CEO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 member">
                            <div>
                                <div className='member-wrapper'>
                                    <img loading="lazy" height="" width=""src="assets/media/sied/matej.png" alt="matej" />
                                </div>
                                <div>
                                    <h2 className="title">Matej Č.</h2>
                                    <p className="subtitle">Co-Founder & CTO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9 mx-auto about-us-text">
                            <p align="center" className='mb-2'>Všetko, čomu sa v SIED Digital Group venujeme, je zamerané na zdokonaľovanie digitálnych procesov a na development návrhov pre podniky pomocou najmodernejších technológií tak, aby sme im pomohli dosiahnuť ich plný potenciál - z hľadiska funkčnosti, ako aj predajnosti.</p>
                            <p align="center">
                            Názov značky “SIED” predstavuje z časti skratku anglického slova “<strong>si</strong>mplifi<strong>ed</strong>” (s použitím prvých a posledných dvoch písmen tohto slova). Vyjadrujeme tým našu víziu a cieľ - doručovať prvotriedne digitálne produkty a dokonale optimalizované riešenia pre použiteľnosť, ktoré sú nezávislé od technickej znalosti konečného užívateľa. Považujeme sa za spoločnosť, ktorá pomáha firmám rásť nad úroveň vašich predstáv prostredníctvom internetu. Práve z tohto konceptu sa odvíja výslovnosť našej značky – “seed” (/si:d/) – čím naznačujeme našu schopnosť spoločne s vami rozvinúť potenciál firmy z malinkého semienka na úspešnú, reprezentatívnu značku.
                            </p>    
                        </div>
                    </div>
                    </div>
                </section>

                <section className="section section-padding">
                    <div className="container">
                    <div className="row">
                    <div className="clients_list d-flex flex-row align-items-center justify-content-between flex-wrap">
                        <div className="client">
                            <img
                            src="/assets/media/sied/brands/corpfin.png"
                            alt="corpfin"
                            height={40}
                            width=""
                            />
                        </div>
                        <div className="client">
                            <img
                            src="/assets/media/sied/brands/eridar.png"
                            alt="eridar"
                            height={58}
                            width=""
                            />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/falkensteiner.png" alt="falkensteiner" height={32} />
                        </div>
                        <div className="client">
                            <img
                            src="/assets/media/sied/brands/fhr.png"
                            alt="fhr"
                            height={65}
                            width=""
                            />
                        </div>
                        <div className="client">
                            <img
                            src="/assets/media/sied/brands/luxie.png"
                            alt="luxie"
                            height={60}
                            width=""
                            />
                        </div>
                        <div className="client">
                            <img
                            src="/assets/media/sied/brands/mobility.png"
                            alt="mobility"
                            height={50}
                            width=""
                            />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/nike1.png" alt="nike1" height={30} width="" />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/petdays.png" alt="petdays" height={82} width="" />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/relocate.png" alt="relocate" height={82} width="" />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/heroes.svg" alt="heroes" height={82} width="" />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/shap.svg" alt="shap" height={82} width="" />
                        </div>
                        <div className="client">
                            <img src="/assets/media/sied/brands/gtinvest.svg" alt="gtinvest" height={82} width="" />
                        </div>
                        </div>
 
                    </div>
                    </div>
                </section>

                {/* <BrandsCarousel /> */}

                <div className={`form-modal`}>
                      <div className={`form-modal-body ${this.state.isModalActive ? "show" : ""}`}>
                        {this.state.formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.isModalActive ? "show" : ""}`}></div>
                    </div>
                {/*=====================================*/}
                {/*=       Location  Area Start        =*/}
                {/*=====================================*/}
                {/* <section className="section section-padding bg-color-dark overflow-hidden">
                    <div className="container">
                    <div className="section-heading heading-light-left">
                        <span className="subtitle">Find Us</span>
                        <h2 className="title">Our office</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-1.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Virginia-HQ</h4>
                            <p>435 Pouros Locks United States</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-2.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Nevada</h4>
                            <p>46 Watsica Creek Suite 071 United States</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-3.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Columbia</h4>
                            <p>7140 Wehner Tunnel Washington, D.C</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-4.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">New Mexico</h4>
                            <p>10 Maggie Valleys United States</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <ul className="shape-group-11 list-unstyled">
                    <li className="shape shape-1">
                        <img loading="lazy" height="" width=""src="assets/media/others/line-6.png" alt="line" />
                    </li>
                    <li className="shape shape-2">
                        <img loading="lazy" height="" width=""src="assets/media/others/circle-3.png" alt="line" />
                    </li>
                    </ul>
                </section> */}
                </>

      )
    }
}

export { About }
