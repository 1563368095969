import React, { Component } from 'react'
import { InlineWidget } from 'react-calendly';
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import sal from 'sal.js';

import Reviews from '../components/Reviews';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
        this.bodyOverflow = this.bodyOverflow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            active: false,
            product: "",
            isModalActive: false,
            formSucc: true,
            submitButtonText: "Odoslať",
            name: '',
            email: '',
            tel: '',
            msg: '',
        };
    }
    escFunction(event) {
        if (event.key === "Escape") {
          this.setState({active: false, isModalActive: false});
          this.bodyOverflow(false);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let tel = this.state.tel;
        let msg = this.state.msg;
        let product = this.state.product;
        const inputs = { name, email, tel, product, msg };
        this.setState({submitButtonText: 'Odosiela sa...'});
        fetch('https://be.siedwebs.sk/api/send-pricing', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Odoslať', active: false, name: '', email: '', tel: '', product: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
        })
        fetch('https://be.siedwebs.sk/api/send-pricing-reply', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.setState({isModalActive: true});
            this.bodyOverflow(true);
        })
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'startProject',
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
      }
    
    bodyOverflow(bool) {
        if(bool) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector('body').removeAttribute("style");
        }
    }
    

    componentDidMount() {
        sal({
          threshold: 0.1,
          once: true
        });
        document.addEventListener("keydown", this.escFunction, false);

        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'pageview',
        //     page: {
        //         url: "/preise",
        //         title: "preise"
        //     }
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    };

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>SIEDwebs | Cenník</title>
                    <meta name="description" content="Ceny prispôsobené Vašim cieľom a potrebám – Biznis, eShop, či Individuálne riešenia." />
                    <link rel="canonical" href='/cennik' />
                    
                    <meta property="og:url" content="https://siedwebs.sk/cennik" />
                    <meta property="og:title" content="SIEDwebs | Cenník" />
                    <meta property="og:description" content="Ceny prispôsobené Vašim cieľom a potrebám – Biznis, eShop, či Individuálne riešenia." />

                    <meta property="twitter:url" content="https://siedwebs.sk/cennik" />
                    <meta name="twitter:title" content="SIEDwebs | Cenník" />
                    <meta name="twitter:description" content="Ceny prispôsobené Vašim cieľom a potrebám – Biznis, eShop, či Individuálne riešenia." />
                </Helmet>
                {/*=====================================*/}
                {/*=       Breadcrumb Area Start       =*/}
                {/*=====================================*/}
                <div className="breadcrum-area">
                    <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                        <li>
                            <Link to="/">Domov</Link>
                        </li>
                        <li className="active">Cenník</li>
                        </ul>
                        <h2 className="title h2">Cenník</h2>
                    </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-11.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                    </ul>
                </div>
                {/*=====================================*/}
                {/*=        Pricing Area Start         =*/}
                {/*=====================================*/}
                
                <section className="section section-padding">
                    
                    <div className="container">
                    <div className="section-heading mb-0 pricing">
                        <span className="subtitle">Služby Prispôsobené Vašim Potrebám ...</span>
                        <h1 className="title h2">Vyberte si Balík</h1>
                        <p>Vaša úspešná digitálna budúcnosť začína práve tu!</p>
                    </div>
                    <div className="row">
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={100}
                        >
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">Biznis</h2>
                            <span className="subtitle">Pre profesionálnu webovú prezentáciu Vás, alebo Vášho podniku.</span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">od 750€</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                            <span onClick={() => {this.setState({active: true, product: "Biznis"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                                Začať&nbsp;Projekt
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <ul className="list-unstyled">
                                <li>One-page/Multi-Page rozhranie</li>
                                <li>Moderný Dizajn</li>
                                <li>Texty podľa kľúčových slov (SEO)</li>
                                <li>Admin Panel - backend prístup</li>
                                <li>Digitálny Katalóg</li>
                                <li>Integrácia Reklamných Platforiem</li>
                                <li>Email Marketing Setup (prepojenie)</li>
                                <li>90 dní podpora zdarma</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={200}
                        >
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">e-Shop</h2>
                            <span className="subtitle">Pre digitálne predaje produktov alebo služieb. </span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">od 1 400€</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                            <span onClick={() => {this.setState({active: true, product: "e-Shop"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                            Začať&nbsp;Projekt
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <ul className="list-unstyled">
                                <li>všetko v Biznis Pláne</li>
                                <li class="add">Platobná Brána</li>
                                <li class="add">Automatizácia Objednávok</li>
                                <li class="add">Automatizácia Fakturácií</li>
                                <li class="add">Admin Panel na Mieru</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={300}
                        >
                        <div className="pricing-table active pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">Riešenia na Mieru</h2>
                            <span className="subtitle">Sofistikované firemné riešenia s individuálnym prístupom.</span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">&mdash;&mdash;</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                                <span onClick={() => {this.setState({active: true, product: "Riešenia na Mieru"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                                Začať&nbsp;Projekt
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <p></p>
                            <ul className="list-unstyled">
                                <li class="none">Spojte sa s nami pre individuálne nacenenie komplexného webového alebo softvérového riešenia na mieru</li>
                                <li>Webové Aplikácie</li>
                                <li>Mobilné Aplikácie</li>
                                <li>Webové Integrácie</li>
                                <li>Bezpečnostné Softvéry</li>
                                <li>Automatizačné Softvéry</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <p align="center">Nenašli ste to, čo ste hladali? Nebojte sa, nájdeme to spolu! Napíšte nám svoju požiadavku na <a href="mailto:mojweb@siedwebs.sk">mojweb@siedwebs.sk</a> a my sa s Vami obratom spojíme.</p>
                    </div>
                    {/* <ul className="shape-group-3 list-unstyled">
                    <li className="shape shape-2">
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-4.png" alt="shape" />
                    </li>
                    </ul> */}
                </section>

                <section className="section section-padding pt-0">
                    <Reviews />
                </section>

                <div className={`pric-form`}>
                        <div className={`form-div ${this.state.active ? "show" : ""}`}>
                            <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`pric-form-close ${this.state.active ? "show" : ""}`}><button aria-label="Zatvoriť formulár"><i className="fas fa-times" /></button></div>
                            {/* <form onSubmit={this.handleSubmit} className="axil-contact-form">
                            <div className="form-group">
                                        <label for="contact-name">Meno alebo Firma *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-name"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({
                                            name: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-email">Email *</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="contact-email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({
                                            email: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-tel">Mobil *</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        name="contact-tel"
                                        value={this.state.tel}
                                        onChange={(e) => this.setState({
                                            tel: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-company">Plán *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-company"
                                        readOnly
                                        value={this.state.product}
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label for="contact-message">Špecifikácie (voliteľné)</label>
                                        <textarea
                                        name="contact-message"
                                        id="contact-message"
                                        className="form-control textarea"
                                        cols={30}
                                        rows={4}
                                        value={this.state.msg}
                                        onChange={(e) => this.setState({
                                            msg: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                        type="submit"
                                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                        name="submit-btn"
                                        aria-label="Odoslať formulár"
                                        >
                                        {this.state.submitButtonText}
                                        </button>
                                    </div>
                            </form> */}
                            <InlineWidget url="https://calendly.com/hannahsied/siedwebs-demo-hovor?hide_gdpr_banner=1&primary_color=acc507" />
                        </div>
                        <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.active ? "show" : ""}`}></div>
                    </div>

                    <div className={`form-modal`}>
                      <div className={`form-modal-body ${this.state.isModalActive ? "show" : ""}`}>
                        {this.state.formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.isModalActive ? "show" : ""}`}></div>
                    </div>
                {/*=====================================*/}
                {/*=           Faq Area Start         =*/}
                {/*=====================================*/}
                {/* <section className="section section-padding-equal bg-color-light faq-area">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Pricing Plan</span>
                            <h3 className="title">Frequently asked questions</h3>
                            <p>
                            Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim
                            gravida pellentesque.
                            </p>
                        </div>
                        </div>
                        <div className="col-lg-7 col-xl-8">
                        <div className="faq-accordion">
                            <div
                            className="accordion"
                            id="accordion"
                            data-sal="slide-up"
                            data-sal-duration={800}
                            data-sal-delay={100}
                            >
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq1"
                                    aria-expanded="true"
                                    aria-controls="faq1"
                                >
                                    1. Nunc rhoncus magna ut venenatis feugiat.
                                </button>
                                </h6>
                                <div
                                id="faq1"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq2"
                                    aria-expanded="false"
                                    aria-controls="faq2"
                                >
                                    2. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq2"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq3"
                                    aria-expanded="false"
                                    aria-controls="faq3"
                                >
                                    3. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq3"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq4"
                                    aria-expanded="false"
                                    aria-controls="faq4"
                                >
                                    4. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq4"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq5"
                                    aria-expanded="false"
                                    aria-controls="faq5"
                                >
                                    5. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq5"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq6"
                                    aria-expanded="false"
                                    aria-controls="faq6"
                                >
                                    6. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq6"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <ul className="shape-group-6 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-7.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-down"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/line-4.png" alt="line" />
                    </li>
                    <li
                        className="shape shape-4"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/poses-lady.png" alt="Poses" />
                    </li>
                    </ul>
                </section> */}
            </>
      )
    }
}

export { Pricing }
