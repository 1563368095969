import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Reviews() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1224, min: 767 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  };

  return <>
          <div className="container">
            <div className="section-heading heading-left">
              <span className="subtitle">Recenzie</span>
              <h2 className="title">Naši klienti hovoria za nás ...</h2>
              <p>Zaručená spokojnosť spočíva z celého procesu našej tvorby a spolupráce s Vami - nie len v dokonalom konečnom výsledku.</p>
            </div>
          </div>
            <div className="row d-none d-sm-block">
              <Carousel 
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                // autoPlaySpeed={3000}
                autoPlaySpeed={1}
                keyBoardControl
                additionalTransfrom={0}
                customTransition="all 7s linear"
                arrows={false}
                swipeable
                pauseOnHover={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                transitionDuration={7000}
                >
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid ">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/ukrpass.png" alt="ukrpass" /></span>
                  <p>“ Výborne vypracované - presne to, čo sme potrebovali. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/davidcantor.png" alt="David Cantor" />
                    </div>
                    <div className="content">
                      <span className="name">David Cantor</span>
                      <span className="designation">Owner &amp; CEO @ Relocate Inc.</span>
                      <span className="designation"><a href="https://ukrpass.eu/" target="_blank" rel='noreferrer noopener'>www.ukrpass.eu</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/funkswoodenwonders.png" alt="Funk's Wooden Wonders LLC" /></span>
                  <p>“ Edwin a jeho tím sú vysoko kvalifikovaní a inovatívni ľudia. Vždy sa na nich obraciame s čímkoľvek, čo súvisí s našim obchodom. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/hadi.png" alt="Hadi A. Alsaud"/>
                    </div>
                    <div className="content">
                      <span className="name">Hadi A. Alsaud</span>
                      <span className="designation">Co-Owner @ Funk's Wooden Wonders LLC</span>
                      <span className="designation"><a href="https://funkswoodenwonders.com/" target="_blank" rel='noreferrer noopener'>www.funkswoodenwonders.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/semotomedia.png" alt="semoto media" /></span>
                  <p>“ Sme veľmi spokojní! ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="Ali Adnan"/>
                    </div>
                    <div className="content">
                      <span className="name">Ali Adnan</span>
                      <span className="designation">Co-Owner @ Semoto Media</span>
                      <span className="designation"><a href="https://semoto-media.com/" target="_blank" rel='noreferrer noopener'>www.semoto-media.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/elsys.png" alt="elsyspro" /></span>
                  <p>“ Veľká spokojnosť pri návrhu a tvorbe web stránky, ochotný prístup k riešeniu našich požiadaviek, skvelé jednanie, komunikácia a vynikajúci výsledok spolupráce. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/elsyspro_v.png" alt="Elsyspro"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Elsyspro</span>
                      <span className="designation"><a href="https://elsyspro.sk/" target="_blank" rel='noreferrer noopener'>www.elsyspro.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/barborka.png" alt="reštauracia barborka" /></span>
                  <p>“ Profesionálny a zodpovedný prístup. Vždy keď sa vyskytol nejaký problém boli ochotný pomôcť. Radi sme spolupracovali. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="barborka"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Reštaurácia Barborka</span>
                      <span className="designation"><a href="https://restauraciabarborka.sk/" target="_blank" rel='noreferrer noopener'>www.restauraciabarborka.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/amsel1.png" alt="amsel" /></span>
                  <p>“ Chalani pracujú tímovo, pružne, profesionálne. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="amsel"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Amsel</span>
                      <span className="designation"><a href="https://amsel.sk/" target="_blank" rel='noreferrer noopener'>www.amsel.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              </Carousel>

            </div>
            <div className="row d-block d-sm-none">
            <div className="col-11 h-100 mx-auto py-3">
                <div className="testimonial-grid ">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/ukrpass.png" alt="ukrpass" /></span>
                  <p>“ Výborne vypracované - presne to, čo sme potrebovali. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/davidcantor.png" alt="David Cantor" />
                    </div>
                    <div className="content">
                      <span className="name">David Cantor</span>
                      <span className="designation">Owner &amp; CEO @ Relocate Inc.</span>
                      <span className="designation"><a href="https://ukrpass.eu/" target="_blank" rel='noreferrer noopener'>www.ukrpass.eu</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 mx-auto py-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/funkswoodenwonders.png" alt="Funk's Wooden Wonders LLC" /></span>
                  <p>“ Edwin a jeho tím sú vysoko kvalifikovaní a inovatívni ľudia. Vždy sa na nich obraciame s čímkoľvek, čo súvisí s našim obchodom. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/hadi.png" alt="Hadi A. Alsaud"/>
                    </div>
                    <div className="content">
                      <span className="name">Hadi A. Alsaud</span>
                      <span className="designation">Co-Owner @ Funk's Wooden Wonders LLC</span>
                      <span className="designation"><a href="https://funkswoodenwonders.com/" target="_blank" rel='noreferrer noopener'>www.funkswoodenwonders.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 mx-auto py-3">
                  <div className="testimonial-grid">
                    <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/elsys.png" alt="elsyspro" /></span>
                    <p>“ Veľká spokojnosť pri návrhu a tvorbe web stránky, ochotný prístup k riešeniu našich požiadaviek, skvelé jednanie, komunikácia a vynikajúci výsledok spolupráce. ”</p>
                    {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                    <div className="author-info">
                      <div className="thumb">
                        <img loading="lazy" height="" width="50"src="assets/media/sied/elsyspro_v.png" alt="Elsyspro"/>
                      </div>
                      <div className="content">
                        <span className="name"></span>
                        <span className="designation">Elsyspro</span>
                        <span className="designation"><a href="https://elsyspro.sk/" target="_blank" rel='noreferrer noopener'>www.elsyspro.sk</a></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-11 h-100 mx-auto py-3">
                  <div className="testimonial-grid">
                    <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/barborka.png" alt="reštauracia barborka" /></span>
                    <p>“ Profesionálny a zodpovedný prístup. Vždy keď sa vyskytol nejaký problém boli ochotný pomôcť. Radi sme spolupracovali. ”</p>
                    {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                    <div className="author-info">
                      <div className="thumb">
                        <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="barborka"/>
                      </div>
                      <div className="content">
                        <span className="name"></span>
                        <span className="designation">Reštaurácia Barborka</span>
                        <span className="designation"><a href="https://restauraciabarborka.sk/" target="_blank" rel='noreferrer noopener'>www.restauraciabarborka.sk</a></span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          {/* <ul className="shape-group-4 list-unstyled">
            <li className="shape-1"><img loading="lazy" height="" width=""src="assets/media/others/bubble-1.png" alt="Bubble" /></li>
          </ul> */}
  </>;
}
